import * as _helperPluginUtils3 from "@babel/helper-plugin-utils";

var _helperPluginUtils2 = "default" in _helperPluginUtils3 ? _helperPluginUtils3.default : _helperPluginUtils3;

import * as _helperAnnotateAsPure3 from "@babel/helper-annotate-as-pure";

var _helperAnnotateAsPure2 = "default" in _helperAnnotateAsPure3 ? _helperAnnotateAsPure3.default : _helperAnnotateAsPure3;

import * as _core3 from "@babel/core";

var _core2 = "default" in _core3 ? _core3.default : _core3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helperPluginUtils = _helperPluginUtils2;
var _helperAnnotateAsPure = _helperAnnotateAsPure2;
var _core = _core2;
const PURE_CALLS = [["react", new Set(["cloneElement", "createContext", "createElement", "createFactory", "createRef", "forwardRef", "isValidElement", "memo", "lazy"])], ["react-dom", new Set(["createPortal"])]];

var _default = (0, _helperPluginUtils.declare)(api => {
  api.assertVersion(7);
  return {
    name: "transform-react-pure-annotations",
    visitor: {
      CallExpression(path) {
        if (isReactCall(path)) {
          (0, _helperAnnotateAsPure.default)(path);
        }
      }

    }
  };
});

exports.default = _default;

function isReactCall(path) {
  const calleePath = path.get("callee");

  if (!calleePath.isMemberExpression()) {
    for (const [module, methods] of PURE_CALLS) {
      for (const method of methods) {
        if (calleePath.referencesImport(module, method)) {
          return true;
        }
      }
    }

    return false;
  }

  const object = calleePath.get("object");
  const callee = calleePath.node;

  if (!callee.computed && _core.types.isIdentifier(callee.property)) {
    const propertyName = callee.property.name;

    for (const [module, methods] of PURE_CALLS) {
      if (object.referencesImport(module, "default") || object.referencesImport(module, "*")) {
        return methods.has(propertyName);
      }
    }
  }

  return false;
}

export default exports;
export const __esModule = exports.__esModule;